<template>
  <div id="master-page">
    <AppHeader />

    <transition name="component-fade" mode="out-in">
      <router-view />
    </transition>

    <!-- Modal -->
    <!-- <modals-container :key="$route.fullPath" /> -->
  </div>
</template>


<script>
  export default {
    watch : {
      '$route.path'() {
        window.scrollTo({ top : 0, behavior : 'auto' });
      },
    },
    components : {
      'AppHeader' : () => import('@/components/layout/AppHeader'),
    },
  }
</script>

<style lang="scss" scoped>
  /* Fade */
  // .component-fade-enter-active, .component-fade-leave-active {
  //   transition: opacity .1s ease;
  // }

  // /* .component-fade-leave-active below version 2.1.8 */
  // .component-fade-enter, .component-fade-leave-to {
  //   opacity: 0;
  // }
</style>

